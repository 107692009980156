<template>
    <div
        class="color-show"
        ref="colorRef"
        @click="isShowColorPicker = true"
        :style="cssProp"
    >
        <transition name="fade">
            <div
                v-show="isShowColorPicker"
                class="picker-wrapper"
                ref="pickerRef"
            >
                <Switch v-model="isGradient" class="gradient-toggle" onOffMod />
                <div v-if="isGradient" class="deg flex">
                    <input
                        type="range"
                        min="0"
                        max="360"
                        v-model="gradientColorValues.deg"
                    />
                    <div class="deg-input">
                        <input
                            type="number"
                            min="0"
                            max="360"
                            v-model="gradientColorValues.deg"
                        />
                        <span>deg</span>
                    </div>
                </div>

                <div v-if="isGradient" class="gradient-container">
                    <div class="gradient-slider-container" ref="gradientRef">
                        <span
                            class="left-color"
                            :class="{ checked: gradientSide === 'left' }"
                            @mousedown="mouseHandler('left')"
                        /><span
                            class="right-color"
                            :class="{ checked: gradientSide === 'right' }"
                            @mousedown="mouseHandler('right')"
                        />
                    </div>
                </div>

                <div class="picker">
                    <div class="color-picker" ref="colorPickerRef">
                        <Chrome
                            v-model="chromeColor"
                            :class="{ 'only-color': !isGradient }"
                        />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { Chrome } from '@ckpack/vue-color'
import Switch from '@/components/Switch.vue'
export default {
    name: 'ColorPiker',
    emits: ['update:modelValue'],
    components: { Chrome, Switch },
    props: {
        modelValue: {
            type: String,
            default: 'rgba(255,0,0,1)'
        },
        id: {
            type: String,
            default: ''
        }
    },
    methods: {
        setPickerPosition: function () {
            this.$nextTick(() => {
                const input = this.$refs.colorRef
                const picker = this.$refs.pickerRef

                const pickerRect = picker.getBoundingClientRect()
                const inputRect = input.getBoundingClientRect()

                // Get window dimensions
                const windowWidth = window.innerWidth
                const windowHeight = window.innerHeight

                // Calculate picker position
                let pickerTop = windowHeight - inputRect.top - pickerRect.height
                let pickerLeft =
                    inputRect.left + inputRect.width / 2 - pickerRect.width / 2

                // Check if picker is off screen
                if (pickerLeft < 0) {
                    pickerLeft = 0
                } else if (pickerLeft + pickerRect.width > windowWidth) {
                    pickerLeft = windowWidth - pickerRect.width
                }

                if (windowHeight - inputRect.bottom > pickerRect.height) {
                    pickerTop = inputRect.bottom + 5
                } else {
                    pickerTop = inputRect.top - pickerRect.height - 5
                }

                this.left = pickerLeft
                this.top = pickerTop
                window.addEventListener('scroll', this.hidePicker, true)
            })
        },
        setVisiblePicker: function (event) {
            this.$nextTick(() => {
                if (!this.$el.contains(event.target))
                    this.isShowColorPicker = false
            })
        },
        hidePicker: function (event) {
            this.$nextTick(() => {
                if (!this.$el.contains(event.target))
                    this.isShowColorPicker = false
            })
        },
        emitColor: function () {
            if (this.isGradient) {
                this.$emit('update:modelValue', this.linerColor)
            } else {
                this.$emit(
                    'update:modelValue',
                    this.chromeColor?.hex8 ?? this.chromeColor
                )
            }
        },
        mouseHandler: function (val) {
            this.gradientSide = val
            this.isMouseDown = true
            window.addEventListener(
                'mousemove',
                this.getSliderGradientPosition,
                true
            )
        },
        getSliderGradientPosition: function (event) {
            const gradientWidth = this.gradientWidth
            const which = event.which ?? 0
            if (which === 1) {
                if (
                    (this.gradientColorValues[`${this.gradientSide}Rate`] ===
                        100 &&
                        event.movementX > 0) ||
                    (this.gradientColorValues[`${this.gradientSide}Rate`] ===
                        0 &&
                        event.movementX < 0)
                )
                    return
                const position = this.checkedLastPosition[
                    `${this.gradientSide}`
                ]
                this.checkedLastPosition[`${this.gradientSide}`] =
                    position + event.movementX
                const rate =
                    this.checkedLastPosition[`${this.gradientSide}`] /
                    gradientWidth

                this.gradientColorValues[`${this.gradientSide}Rate`] =
                    rate * 100

                switch (true) {
                    case position > gradientWidth:
                        this.checkedLastPosition[
                            `${this.gradientSide}`
                        ] = gradientWidth
                        this.gradientColorValues[
                            `${this.gradientSide}Rate`
                        ] = 100
                        break
                    case position < 0:
                        this.checkedLastPosition[`${this.gradientSide}`] = 0
                        this.gradientColorValues[`${this.gradientSide}Rate`] = 0
                        break
                }
            } else {
                this.isMouseDown = false
            }
        }
    },
    computed: {
        cssProp: function () {
            return {
                '--picker-top': `${this.top}px`,
                '--picker-left': `${this.left}px`,
                '--picker-color': `${this.modelValue}`,
                '--left-color': `${this.gradientColorValues.leftColor}`,
                '--right-color': `${this.gradientColorValues.rightColor}`,
                '--left-rate': `${this.checkedLastPosition.left}px`,
                '--right-rate': `${this.checkedLastPosition.right}px`,
                '--liner-color': `linear-gradient(90deg, ${this.gradientColorValues.leftColor} ${this.gradientColorValues.leftRate}%, ${this.gradientColorValues.rightColor} ${this.gradientColorValues.rightRate}%)`
            }
        },
        linerColor: function () {
            return `linear-gradient(${this.gradientColorValues.deg}deg, ${this.gradientColorValues.leftColor} ${this.gradientColorValues.leftRate}%, ${this.gradientColorValues.rightColor} ${this.gradientColorValues.rightRate}%)`
        },
        gradientWidth: function () {
            let width = 215
            this.$nextTick(() => {
                const dom = this.$refs.gradientRef
                width = dom.clientWidth
            })

            return width
        }
    },
    watch: {
        isShowColorPicker: function (val) {
            if (val) this.setPickerPosition()
        },
        chromeColor: {
            handler() {
                if (this.isGradient) {
                    this.gradientColorValues[`${this.gradientSide}Color`] =
                        this.chromeColor?.hex8 ?? this.chromeColor
                }
                this.emitColor()
            },
            deep: true
        },
        gradientColorValues: {
            handler() {
                this.emitColor()
            },
            deep: true
        },
        isGradient: function (val) {
            if (val) {
                this.checkedLastPosition.right = this.gradientWidth
                const checked = this.gradientSide
                this.chromeColor = this.gradientColorValues[`${checked}Color`]
                this.emitColor()
            }
        },
        gradientSide: function () {
            if (this.isGradient) {
                const checked = this.gradientSide
                this.chromeColor = this.gradientColorValues[`${checked}Color`]
            }
        },
        isMouseDown: function () {
            if (!this.isMouseDown)
                window.removeEventListener(
                    'mousemove',
                    this.getSliderGradientPosition,
                    true
                )
        }
    },
    data() {
        return {
            top: 0,
            left: 0,
            isShowColorPicker: false,
            chromeColor: '#ffffff',
            isGradient: false,
            gradientColorValues: {
                deg: 0,
                leftRate: 0,
                leftColor: 'rgba(0, 173, 238, 1)',
                rightRate: 100,
                rightColor: 'rgba(107, 139, 228, 1)'
            },
            gradientSide: 'left',
            isMouseDown: false,
            checkedLastPosition: {
                left: 0,
                right: 0
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.setPickerPosition()
            window.addEventListener('resize', this.setPickerPosition, true)
            window.addEventListener('click', this.setVisiblePicker, true)
        })
    }
}
</script>

<style lang="scss" scoped>
.color-show {
    position: relative;
    cursor: pointer;
    width: 25px;
    height: 25px;
    border: 3px solid #fff;
    outline: 1px solid $fourth-grey;
    border-radius: 50%;
    font-family: $number-en;

    background-image: repeating-linear-gradient(#eee 0 4px, transparent 0 8px),
        repeating-linear-gradient(90deg, #eee 0 4px, transparent 0 8px);
    background-blend-mode: screen;

    &::after {
        content: '';
        background: var(--picker-color);
        width: 25px;
        height: 25px;
        position: absolute;
        border-radius: 50%;
        z-index: 1;
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
    }
}

.gradient-toggle {
    width: 100%;
    :deep(.switcher) {
        width: 100%;
        height: 30px;
        &::before {
            content: '漸層';
            height: 24px;
            border-radius: 4px;
            box-sizing: border-box;
            padding: 0 20% 0 0;
            justify-content: flex-end;
            align-items: center;
            transition-duration: 0.2s;
            color: $primary-white;
            @extend %toggleBefore;
        }
        &::after {
            content: '單色';
            top: 3px;
            left: 3px;
            width: 49%;
            height: 18px;
            border-radius: 3px;
            transition-duration: 0.2s;
            color: $eighth-grey;
            @extend %toggleAfter;
        }
        &:checked {
            &::before {
                content: '單色';
                margin: 0 auto;
                left: calc(50% - 1px);
                @extend %toggleBefore;
                box-sizing: border-box;
                box-sizing: border-box;
                padding: 0 0 0 20%;
                justify-content: flex-start;
                align-items: center;
                color: $primary-white;
            }
            &::after {
                content: '漸層';
                margin: 0 auto;
                left: calc(50% - 1px);
                color: $primary-blue;
                @extend %toggleAfter;
            }
        }
    }
}

.picker-wrapper {
    position: fixed;
    top: var(--picker-top);
    left: var(--picker-left);
    z-index: 11;
    background: $primary-white;
    box-shadow: 0 0px 6px 0px rgba(0, 0, 0, 0.1);
    padding: 8px;
    height: 330px;
    border-radius: 5px;
    font-size: 12px;
    word-break: break-all;
    text-align: center;
    line-height: 14px;
    cursor: default;
}

:deep(.vc-chrome) {
    box-shadow: none;

    .vc-chrome-body {
        padding: 15px 10px;
    }
    .vc-chrome-color-wrap {
        margin-right: 10px;
        .vc-chrome-active-color {
            border: 1px solid$third-grey;
            border-radius: 50%;
        }
    }
}

.deg {
    justify-content: space-between;
    margin: 5px 0 10px;
}

input[type='range'] {
    width: 100%;
    -webkit-appearance: none;
    background: none;
    outline: none;
    border-radius: 0;
    cursor: pointer;
    &::-webkit-slider-runnable-track {
        height: 10px;
        border-radius: 4px;
        background: $ninth-grey;
        transform: scaleX(0.98);
    }
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        background-color: #f8f8f8;
        border-radius: 6px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
        height: 12px;
        width: 12px;
        transform: translate(0px, -1px);
    }
}

.deg-input {
    display: flex;
    justify-content: center;
    align-items: center;
    input[type='number'] {
        width: auto;
        margin: 0 5px;
        &:focus-visible {
            outline: auto 1px $primary-blue;
        }
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Firefox */
        input[type='number'] {
            -moz-appearance: textfield;
        }
    }
    span {
        display: inline-block;
        width: 20px;
    }
}

%toggleBefore {
    display: flex;
}
%toggleAfter {
    display: flex;
    justify-content: center;
}

.gradient-container {
    height: 10px;
    margin-bottom: 10px;
    background: var(--liner-color);
    border-radius: 2px;
    .gradient-slider-container {
        position: relative;
        .left-color,
        .right-color {
            position: absolute;
            top: -2px;
            display: block;
            cursor: pointer;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 1px solid #fff;
            outline: 1px solid $fourth-grey;
            box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1) inset;
            &.checked {
                outline: 2px solid $fifth-black;
            }
        }
        .left-color {
            background: var(--left-color);
            left: var(--left-rate);
        }
        .right-color {
            background: var(--right-color);
            left: var(--right-rate);
        }
    }
}

.only-color {
    :deep(.vc-chrome-saturation-wrap) {
        height: 50px;
    }
}
</style>
